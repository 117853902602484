<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import { required } from "vuelidate/lib/validators";
import EmptyList from "@/components/widgets/empty_list"

import {
	projectMethods,
  searchAnalyticsMethods
} from "@/state/helpers";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Search Clusters",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    EmptyList
  },
  data() {
    return {
      title: "Clusters",
      items: [],
      showModalHost: false,
      showModalProducts:false,
      isLoading: false,
      fullPage: true,
      canCancel: false,
      useSlot: false,
      color: "#007bff",
      bgColor: "#ffffff",
      height: 128,
      width: 128,
      project:null,
      submitted:false,
      projects:[],
      emptyList:false ,
      clusters:[],
      showSearchClusterDetail:false,
      clustersDetails:[],
      months:[
        this.$t('common.months.january'),
        this.$t('common.months.february'),
        this.$t('common.months.march'),
        this.$t('common.months.april'),
        this.$t('common.months.may'),
        this.$t('common.months.june'),
        this.$t('common.months.july'),
        this.$t('common.months.august'),
        this.$t('common.months.september'),
        this.$t('common.months.october'),
        this.$t('common.months.november'),
        this.$t('common.months.december')
      ],
      empty_config:{
					"title" : this.$t('search-clusters.empty_title'),
					"subtitle" : this.$t('search-clusters.empty_text'),
					"buttonText" : null,
					icon: "fa-cubes"
			},
    }
  },
  validations: {
    project: {
      name: {
        required,
      }
    },
  },
  mounted() {
    this.loadProjectSearchClusters();
  },
  created() {
    
  },
  methods: {
    ...projectMethods,
    ...searchAnalyticsMethods,

    loadProjectSearchClusters(){
      let loader = this.$loading.show();
      this.isLoading = true;
      const params = `project=${localStorage.getItem('current_project')}`
      
      this.getSearchClusters(params).then((data)=>{

        if(!data || !data.data || data.data.length == 0){
            this.clusters = [];
            this.emptyList = true;
            return;
        }
        this.emptyList = false;
        this.clusters = data.data;
        
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('search-clusters.get_clusters_error'), title: this.$t('search-clusters.title') });
      }).finally(()=>{
        this.isLoading = false;
        loader.hide();
      });
    },

    async onDetailsClicked(cluster){
        
        this.clustersDetails = [];
        let loader = this.$loading.show();

        fetch(cluster.uri)
        .then(response => {
            return response.json()
        })
        .then((data)=>{
            this.clustersDetails = data.sort((a, b) => b.data?.length - a.data?.length);
            this.showSearchClusterDetail = true;
        }).catch(()=>{
            this.showSearchClusterDetail = false;
            this.$notify({ type: 'error', text: this.$t('search-clusters.get_clusters_error'), title: this.$t('search-clusters.title') });
        }).finally(()=>{
            loader.hide();
        })
        
    },

    getClusterName(date){
        const d = new Date(date);

        const from = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 7);
        const day = from.getDay();
        const diff = from.getDate() - day + (day === 0 ? -6 : 1)
        const monday = new Date(from.setDate(diff))

        const sunday = new Date(monday)
        sunday.setDate(sunday.getDate() + 6)

        if(monday.getMonth() == sunday.getMonth()){
            return this.$t('search-clusters.cluster_date_same_month').replace('#',monday.getDate()).replace('##',sunday.getDate()).replace('###',this.months[monday.getMonth()]).replace('####',monday.getFullYear());
        }else{
            if(monday.getFullYear() == sunday.getFullYear()){
                return this.$t('search-clusters.cluster_date_different_month').replace('#',monday.getDate()).replace('##',this.months[monday.getMonth()]).replace('###',sunday.getDate()).replace('####',this.months[sunday.getMonth()]).replace('#####',monday.getFullYear());
            }else{
                return this.$t('search-clusters.cluster_date_different_month_year').replace('#',monday.getDate()).replace('##',this.months[monday.getMonth()]).replace('###',monday.getFullYear()).replace('####',sunday.getDate()).replace('#####',this.months[sunday.getMonth()]).replace('######',sunday.getFullYear());
            }   
        }
    },
    getDataFromCustomerDetails(data){
        let q = '';
        data.forEach(element => {
            q+= '- ' + element + ' <br>'
        });

        return q;
    }
},
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="card mb-3" v-if="!isLoading && clusters.length == 0 ">
        <div class="card-body p-0">
          <EmptyList :config="empty_config" class="mt-3 mb-3"/>
        </div>
    </div>
    <div class="row" v-if="!isLoading && clusters.length > 0 ">
      <div class="col-lg-12">
        <div>
          <div class="table-responsive">
            <table class="table project-list-table table-nowrap table-centered table-borderless align-middle">
              <thead>
                <tr>
                  <th scope="col">{{$t('search-clusters.date')}}</th>
                  <th scope="col">{{$t('search-clusters.type')}}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="cluster in clusters" :key="cluster.id">
                  <td>
                     {{ getClusterName(cluster.created_at) }} 
                  </td>
                  <td>
                    <span class="badge font-size-10"
                        :class="{
                        'bg-success': `${cluster.report}` === 'cluster-S_S',
                        'bg-danger': `${cluster.report}` === 'cluster-U_S',
                    }">{{ cluster.report == 'cluster-S_S' ? $t('search-clusters.type_with_results') : $t('search-clusters.type_without_results')  }}</span>
                </td>
                <td>
                    <i class= 'bx bx-detail interact' v-on:click="onDetailsClicked(cluster)" />
                </td>
                </tr>   
              </tbody>
            </table>
          </div>
        </div>
    </div>
    <b-modal
      v-model="showSearchClusterDetail"
      :title= "$t('search-clusters.title')"
      title-class="text-black font-18"
      body-class="p-3"
      hide-footer>
      <div>
        <div role="tablist">
              <b-card no-body class="mb-1 shadow-none" v-for="(c,i) in clustersDetails" :key="c.center">
                <b-card-header header-tag="header" role="tab" v-b-toggle="'accordion-' + i" class="interact">
                  <h6 class="m-0">
                    <a
                      class="text-dark"
                      href="javascript: void(0);">{{`${c.center} (${c.data?.length})`}}</a>
                  </h6>
                </b-card-header>
                <b-collapse :id="'accordion-' + i" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text v-html="getDataFromCustomerDetails(c.data)"></b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
      </div>
    </b-modal>
</div>
  </Layout>
</template>

<style scoped>
.interact{
	cursor: pointer;
  }
</style>


